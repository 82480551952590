import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import PageRoot from '../../components/common-components/templates/page-root-v2';
import Works from '../../components/work-v2/index';
import colorPaletteV2 from '../../styles/color-palette-v2';
import '../../styles/foundation-v2.css';
import WorkFluidImages from '../../images/gatsby-image/v2-work-page/fluid-images';
import WorkDetailFluidImages from '../../images/gatsby-image/work-detail-page/fluid-images';
import pagesV2 from '../../constants-v2/pages-v2';
import SEOHeaderV2 from '../../components/common-components/templates/page-root-v2/seo-v2';

const wpContentSideMarginSp = 2.4;

const captionWidthPc = '608px';
const captionWidthSp = `calc(100% - ${wpContentSideMarginSp * 2}px)`;

const contentFontH = {
  sp: {
    h1: {
      font: '24px',
      line: '36px',
    },
    h2: {
      font: '24px',
      line: '36px',
    },
    h3: {
      font: '21px',
      line: '31.5px',
    },
    h4: {
      font: '17px',
      line: '27px',
    },
  },
  pc: {
    h1: {
      font: '24px',
      line: '34.75px',
    },
    h2: {
      font: '24px',
      line: '34.75px',
    },
    h3: {
      font: '18px',
      line: '24.06px',
    },
    h4: {
      font: '16px',
      line: '23.17px',
    },
  },
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
`;

const BodyWrapper = styled.div`
  z-index: 1;
  margin-bottom: 160px;
  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 100px;
  }
`;

const CaptionRightAlign = styled(Link)`
  display: flex;
  align-items: center;
  width: 110px;
  margin: 0 0 24px auto;
  @media (max-width: 1023px) {
    margin-bottom: 18px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`;

const TopAttention = styled.h2`
  font-weight: 400;
  color: ${colorPaletteV2.blue};
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 16px;
    margin-left: 4px;
  }
`;

const WorkContentWrapper = styled.div`
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
  }
`;

const EyeCatchPic = styled(Img)`
  border: 0;
  background-size: cover;
  @media (max-width: 1023px) {
    width: ${captionWidthSp};
    height: 320px;
    margin: 0 auto 24px;
  }
  @media (min-width: 1024px) {
    width: ${captionWidthPc};
    height: 420px;
    margin-bottom: 28px;
  }
  @media (max-width: 640px) {
    height: 210px;
  }
`;

const WorkTitle = styled.h1`
  font-size: 24px;
  line-height: 34.75px;
  font-weight: 700;
  @media (max-width: 1023px) {
    margin-bottom: 12px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 14px;
  }
`;

const WorkTags = styled.div`
  @media (max-width: 1023px) {
    display: block;
    line-height: 28.8px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    margin-bottom: 40px;
  }
`;

const WorkTagsItem = styled.span`
  font-size: 16px;
  color: ${colorPaletteV2.blue};
  display: inline;
  margin-right: 5px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

const WorksOuter = styled.div`
  margin-bottom: 100px;
`;

const WorkDescriptionWrapper = styled.div`
  font-size: 16px;
  font-size: 16px;
  line-height: 28.8px;
  line-height: 28.8px;

  & h2 {
    padding: 2px 8px;
    border-left: solid 6px ${colorPaletteV2.blue};
    margin-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-bottom: 20px;

    @media (max-width: 1023px) {
      font-size: ${contentFontH.sp.h2.font};
      line-height: ${contentFontH.sp.h2.line};
    }
    @media (min-width: 1024px) {
      font-size: ${contentFontH.pc.h2.font};
      line-height: ${contentFontH.pc.h2.line};
    }
  }
`;

const WorkParagraph = styled.p`
  font-size: 16px;
  font-size: 16px;
  line-height: 28.8px;
  line-height: 28.8px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 24px;
`;

const WorkBlockImagePC = styled(Img)`
  @media (min-width: 1024px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const WorkBlockImageSP = styled(Img)`
  @media (min-width: 1024px) {
    display: none;
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    margin: 0 auto 20px;
  }
  //TO DO:2021/03/30:公開後タブレット用画像用意する？
`;

const ValveMaintenanceSystem = () => {
  return (
    <PageRoot>
      <SEOHeaderV2 pageTitle="実績紹介｜ハードとソフトが融合！超高圧バルブメンテナンスシステム" />
      <Root className="wrapper-v2">
        <BodyWrapper>
          <CaptionRightAlign to={pagesV2.works.path}>
            <TopAttention>実績紹介一覧</TopAttention>
          </CaptionRightAlign>
          <WorkContentWrapper>
            <EyeCatchPic fluid={WorkFluidImages('valveMaintenanceSystem')} />
            <WorkTitle>
              ハードとソフトが融合！
              <br />
              超高圧バルブメンテナンスシステム
            </WorkTitle>
            <WorkTags>
              <WorkTagsItem>#管理システム</WorkTagsItem>
              <WorkTagsItem>#メンテナンス</WorkTagsItem>
              <WorkTagsItem>#クラウドシステム</WorkTagsItem>
            </WorkTags>
            <WorkDescriptionWrapper>
              <h2>プロジェクトの背景</h2>
              <WorkParagraph>
                &emsp;小型から大型のものまで発電所や化学プラント向けの超高圧バルブを製造する岡野バルブ製造株式会社様は、既存のバルブ販売事業に続く新たな収益源を模索していました。
                <br />
                そんな中、新規事業として、バルブに紐づく情報が一元管理可能なバルブ管理システムを立ち上げ、次世代バルブメンテナンス事業を立ち上げることになりました。
                <br />
                バルブの開発出荷時からメンテナンスまでの情報を一元管理し、劣化や不具合情報を遠隔でもリアルタイムで把握することで積極的なメンテナンス推奨を行うサービスを提供することを目指しました。
              </WorkParagraph>
              <h2>取り組み内容</h2>
              <WorkBlockImagePC
                fluid={WorkDetailFluidImages(
                  'valveMaintenanceSystemDescktop01',
                )}
              />
              <WorkBlockImageSP
                fluid={WorkDetailFluidImages('valveMaintenanceSystemMobile01')}
              />

              <WorkParagraph>
                &emsp;バルブの情報を全国どこからでもWebブラウザで入力、閲覧できるように、クラウドシステムとして構築することになりました。
                <br />
                Amazon&nbsp;Web&nbsp;Services上にRuby&nbsp;on&nbsp;RailsでWebアプリケーションを実装し、できる限り運用コストを下げる為、アーキテクチャは極力シンプルなものとしました。
                <br />
                ORCLというバルブ診断装置（※他社様製造）との連携も可能にし、同装置で取得したバルブの診断結果をアプリケーションにアップロードすることで、バルブのリアルな「健康状態」をクラウド上で一元管理することが可能になりました。
                <br />
                さらに、同社内でVQ事業部という新組織も立ち上がり、弊社はハードとソフト、リアルとデジタルを繋げ継続開発/運用を行いながら新組織の事業をサポートさせて頂いています。
              </WorkParagraph>
            </WorkDescriptionWrapper>
          </WorkContentWrapper>
        </BodyWrapper>
        <WorksOuter>
          <Works />
        </WorksOuter>
      </Root>
    </PageRoot>
  );
};
export default ValveMaintenanceSystem;
